import React, { useState, useEffect } from 'react'
import { BASE_URL } from './Constants'

const PhotosList = ({ photosData, albumsPhotosData, activeAlbum, activeFilter, activePhoto, setActivePhoto }) => {
    const [photosColumns, setPhotosColumns] = useState('columns-8')
    const [albumPhotos, setAlbumPhotos] = useState([])
    useEffect(() => {
        if (albumsPhotosData && activeAlbum) {
            const photos = albumsPhotosData.map((data) => {
                if (data.albumId === activeAlbum) {
                    return data.photoId
                }
                return false
            })
            setAlbumPhotos(photos)
        } else if (photosData && activeFilter) {
            const photos = Object.keys(photosData).filter((photo) => {
                switch (activeFilter) {
                    case 'all':
                        return true
                    case 'new':
                        return photosData[photo].status === 'new'
                    case 'active':
                        return photosData[photo].status === 'active'
                    case 'hidden':
                        return photosData[photo].status === 'hidden'
                    case 'no-album':
                        return false
                    case 'no-date':
                        return photosData[photo].dateTaken === ''
                    case 'no-comments':
                        return photosData[photo].comments === ''
                    case 'no-title':
                        return photosData[photo].title === ''
                    default:
                        return false
                }
            })
            setAlbumPhotos(photos)
        } else {
            setAlbumPhotos([])
        }
    }, [photosData, albumsPhotosData, activeAlbum, activeFilter])

    return (
        <div>
            <div>
                <select name='selectcolumns' value={photosColumns} onChange={e => setPhotosColumns(e.target.value)} className='absolute hidden p-1 opacity-80'>
                    <option value='columns-6'>XL</option>
                    <option value='columns-8'>L</option>
                    <option value='columns-10'>M</option>
                    <option value='columns-12'>S</option>
                </select>
            </div>
            <div className={`w-full p-5 pb-10 mx-auto mb-10 gap-5 ${photosColumns} space-y-5`}>
                {
                    Object.keys(photosData).map(photo => {
                        if (!albumPhotos.includes(photo)) {
                            return false
                        }
                        let border = activePhoto === photosData[photo].photoId ? 'border-8 border-stone-800' : ''
                        return (
                            <div key={photo}>
                                <img src={`${BASE_URL}/images/thumbs/square-${photosData[photo].filename}`} alt='A' onClick={() => setActivePhoto(photosData[photo].photoId)} className={`shadow cursor-pointer ${border}`} />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default PhotosList