import React from 'react'

const AlbumsList = ({ albumsData, activeAlbum, setActiveAlbum, activeFilter, setActiveFilter, setActivePhoto }) => {

    const setAlbum = (id) => {
        setActiveAlbum(id)
        setActiveFilter(null)
        setActivePhoto(null)
    }

    const setFilter = (id) => {
        setActiveAlbum(null)
        setActiveFilter(id)
        setActivePhoto(null)
    }

    const filtersList = {
        'all': 'All Photos',
        'new': 'Status: New',
        'active': 'Status: Active',
        'hidden': 'Status: Hidden',
        'no-album': 'No Album',
        'no-date': 'No Date',
        'no-title': 'No Title',
        'no-comments': 'No Comments'
    }

    return (
        <div>
            {
                Object.keys(filtersList).map(filter => {
                    let background = activeFilter === filter ? 'bg-stone-200 font-bold' : ''
                    return (
                        <div key={filter} onClick={() => setFilter(filter)} className={`p-1 text-sm cursor-pointer ${background}`}>{filtersList[filter]}</div>
                    )
                })
            }
            <hr className='my-2' />
            {
                Object.keys(albumsData).map(album => {
                    let background = activeAlbum === albumsData[album].albumId ? 'bg-stone-200 font-bold' : ''
                    return (
                        <div key={album} onClick={() => setAlbum(albumsData[album].albumId)} className={`p-1 text-sm cursor-pointer ${background}`}>
                            {albumsData[album].title}
                        </div>
                    )
                })
            }
            <hr className='mt-2' />
        </div>
    )
}

export default AlbumsList