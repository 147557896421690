import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Editor from './Editor'
import AlbumView from './AlbumView'
//import AlbumsView from './AlbumsView'

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<AlbumView />} />
      <Route path='editor' element={<Editor />} />
      <Route path='/albums/' element={<AlbumView />} />
      <Route path='/albums/:album' element={<AlbumView />} />
      <Route path='/albums/:album/:photo' element={<AlbumView />} />
    </Routes>
  )
}
export default App