import React, { useEffect } from 'react'
import { BASE_URL } from './Constants'

const AlbumForm = ({ setDataChanged, activeAlbum, setActiveAlbum, albumsData, setFormMessage }) => {

    const [albumTitle, setAlbumTitle] = React.useState('')
    const [albumComments, setAlbumComments] = React.useState('')
    const [albumSlug, setAlbumSlug] = React.useState('')
    const [deleteSafe, setDeleteSafe] = React.useState(false)

    useEffect(() => {
        if (activeAlbum) {
            setAlbumTitle(albumsData[activeAlbum].title)
            setAlbumComments(albumsData[activeAlbum].comments)
            setAlbumSlug(albumsData[activeAlbum].slug)
        } else {
            setAlbumTitle('')
            setAlbumComments('')
            setAlbumSlug('')
        }
        setDeleteSafe(false)
    }, [activeAlbum, albumsData])

    const handleChange = (event) => {
        const { name, value, checked } = event.target

        switch (name) {
            case 'title':
                setAlbumTitle(value)
                break
            case 'comments':
                setAlbumComments(value)
                break
            case 'slug':
                setAlbumSlug(value)
                break
            case 'deleteCheck':
                setDeleteSafe(checked)
                break
            default:
                break
        }
    }

    const cancelAlbumEdit = (event) => {
        event.preventDefault()
        setActiveAlbum(null)
    }

    const deleteAlbum = async (event) => {
        event.preventDefault()
        if (deleteSafe === false) {
            return false
        }
        try {
            const result = await fetch(`${BASE_URL}/api/albums/${activeAlbum}`, {
                method: 'DELETE'
            })
            const data = await result.json()
            if (result.status === 200) {
                setActiveAlbum(null)
                setDataChanged(true)
                setFormMessage('Album Deleted')
            } else {
                setFormMessage('There was an error...')
            }
            console.log(data)
        } catch (error) {
            console.error(error)
        }
    }

    const postAlbumData = async (event) => {
        event.preventDefault()
        try {
            let url
            let method
            let body
            if (activeAlbum) {
                url = `${BASE_URL}/api/albums/${activeAlbum}`
                method = 'PUT'
                body = {
                    ...albumsData[activeAlbum],
                    title: albumTitle,
                    comments: albumComments,
                    slug: albumSlug
                }
            } else {
                url = `${BASE_URL}/api/albums/`
                method = 'POST'
                body = {
                    title: albumTitle,
                    comments: albumComments,
                    slug: albumSlug
                }
            }
            const result = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            const data = await result.json()
            if (result.status === 200) {
                setFormMessage('Album Updated')
                setDataChanged(true)
            } else if (result.status === 201) {
                setFormMessage('Album Created')
                setDataChanged(true)
            } else {
                setFormMessage('There was an error...')
            }
            console.log(data)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div>
            <form onSubmit={postAlbumData}>
                <input type="text" name="slug" placeholder="Slug" value={albumSlug} onChange={e => handleChange(e)} className={`w-full p-2 mt-2 border-stone-200 border`} /><br />
                <input type="text" name="title" placeholder="Title" value={albumTitle} onChange={e => handleChange(e)} className={`w-full p-2 mt-2 border-stone-200 border`} /><br />
                <textarea name="comments" placeholder="Comments" value={albumComments} onChange={e => handleChange(e)} className={`w-full p-2 mt-2 border-stone-200 border`} /><br />
                <button disabled={albumSlug === '' || albumTitle === ''} className={`disabled:opacity-20 disabled:cursor-not-allowed bg-stone-600 text-stone-100 py-1 px-3 mt-1`}>{activeAlbum ? `Edit Album` : `Create Album`}</button>
            </form>
            {
                activeAlbum && (
                    <form onSubmit={deleteAlbum} className={`mt-2`}>
                        <input type='checkbox' name='deleteCheck' checked={deleteSafe} onChange={e => handleChange(e)} />
                        <button disabled={deleteSafe === false} className={`disabled:opacity-20 disabled:cursor-not-allowed bg-stone-600 text-stone-100 py-1 px-3 ml-2`}>Delete Album</button>
                    </form>
                )
            }
            <form onSubmit={cancelAlbumEdit}>
                <button className={`hidden bg-stone-200 border border-stone-300 text-stone-600 py-1 px-3 mt-2`}>Cancel</button>
            </form>
        </div>
    )
}
export default AlbumForm