import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { BASE_URL } from './Constants'

const AlbumView = () => {
    const params = useParams()
    const [dataChanged, setDataChanged] = useState(true)
    const [activeAlbum, setActiveAlbum] = useState()
    const [activeAlbumId, setActiveAlbumId] = useState()
    const [albumsData, setAlbumsData] = useState(false)
    const [activePhoto, setActivePhoto] = useState(params.photo)
    const navigate = useNavigate()

    useEffect(() => {
        if (dataChanged === true) {
            fetch(`${BASE_URL}/api/albums/`, { cache: 'no-store' }).then(response => {
                return response.json()
            }).then(json => {
                setAlbumsData(() => json)
            })
            setDataChanged(() => false)
        }
    }, [dataChanged])

    useEffect(() => {
        if (activeAlbum && albumsData) {
            Object.keys(albumsData).map(slug => {
                if (slug === activeAlbum) {
                    setActiveAlbumId(albumsData[slug].albumId)
                }
                return true
            })
        }
    }, [activeAlbum, albumsData])

    useEffect(() => {
        setActiveAlbum(params.album)
        setActivePhoto(params.photo)
    }, [params.album, params.photo])

    const navigateMenu = (e) => {
        if (e.target.value !== 'false') {
            navigate(`/albums/${e.target.value}`)
        } else {
            setActiveAlbum()
            setActivePhoto()
            navigate(`/`)
        }
    }

    return albumsData && (
        <div className={`min-h-screen overflow-auto wallpaper`}>
            <select name='selectcolumns' value={activeAlbum} onChange={e => navigateMenu(e)} className='fixed top-0 left-0 z-0 w-full p-4 font-bold shadow outline-none'>
                <option className={`font-bold`} value={false}>Home</option>
                {
                    Object.keys(albumsData).map(slug => {
                        return (
                            <option key={slug} value={slug}>{albumsData[slug].title}</option>
                        )
                    })
                }
            </select>
            {
                activePhoto && (
                    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-full opacity-100 '>
                        <div onClick={() => window.history.back()} className='absolute z-10 w-full h-full bg-gray-900 cursor-pointer opacity-80'></div>
                        <div onClick={() => window.history.back()} className='z-50 p-2 mx-2 overflow-y-auto shadow-lg cursor-pointer md:p-4 bg-stone-50'>
                            <img src={`${BASE_URL}/images/crops/1080-${activePhoto}.jpg`} alt='A' className={`w-full max-w-[100%] max-h-[800px]`} />
                        </div>
                    </div>
                )
            }
            <div className={`lg:max-w-7xl md:max-w-7xl p-5 py-10 mx-auto mt-10 mb-10 gap-5 lg:columns-4 columns-2 md:columns-3 space-y-5`}>
                {
                    !activeAlbum && albumsData && Object.keys(albumsData).map(slug => {
                        return albumsData[slug].cover && (
                            <div key={slug} className='break-inside-avoid-column'>
                                <Link to={`/albums/${albumsData[slug].slug}`}>
                                    <img src={`${BASE_URL}/images/thumbs/lr-${albumsData[slug].photos[albumsData[slug].cover].filename}`} alt='A' className='mx-auto border-8 shadow-lg border-stone-50' />
                                    <p className='text-center'>{`${albumsData[slug].title}`}</p>
                                </Link>
                            </div>
                        )
                    })
                }
                {
                    activeAlbum && Object.keys(albumsData[activeAlbum].photos).map(photo => {
                        return albumsData[activeAlbum].photos[photo] && (
                            <div key={photo} className='break-inside-avoid-column'>
                                <Link to={`/albums/${activeAlbum}/${photo}`}>
                                    <img src={`${BASE_URL}/images/thumbs/lr-${albumsData[activeAlbum].photos[photo].filename}`} alt='A' className='mx-auto border-8 shadow-lg border-stone-50' />
                                </Link>
                            </div>
                        )
                    })
                }
            </div>
            {
                albumsData[activeAlbumId] && (
                    <p className='text-center'>{albumsData[activeAlbumId].comments}</p>
                )
            }
        </div>
    )
}

export default AlbumView