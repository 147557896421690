import React, { useEffect } from 'react'
import { BASE_URL } from './Constants'

const PhotoForm = ({ setDataChanged, activePhoto, setActivePhoto, activeAlbum, setActiveAlbum, photosData, albumsData, albumsPhotosData, setFormMessage, setModalActive }) => {

    const [photoTitle, setPhotoTitle] = React.useState('')
    const [photoComments, setPhotoComments] = React.useState('')
    const [photoStatus, setPhotoStatus] = React.useState('')
    const [photoDateTaken, setPhotoDateTaken] = React.useState('')
    const [photoAlbums, setPhotoAlbums] = React.useState([])
    const [selectedAlbum, setSelectedAlbum] = React.useState('')
    const [deleteSafe, setDeleteSafe] = React.useState(false)

    useEffect(() => {
        if (activePhoto) {
            const albums = albumsPhotosData.map((data) => {
                if (data.photoId === activePhoto) {
                    return data.albumId
                }
                return false
            })
            setPhotoTitle(photosData[activePhoto].title)
            setPhotoComments(photosData[activePhoto].comments)
            setPhotoStatus(photosData[activePhoto].status)
            setPhotoDateTaken(photosData[activePhoto].dateTaken)
            setPhotoAlbums(albums)
            setDeleteSafe(false)
            setSelectedAlbum('')
        }
    }, [albumsPhotosData, photosData, activePhoto])

    const handleChange = (event) => {
        const { name, value, checked } = event.target
        switch (name) {
            case 'title':
                setPhotoTitle(value)
                break
            case 'comments':
                setPhotoComments(value)
                break
            case 'status':
                setPhotoStatus(value)
                break
            case 'dateTaken':
                setPhotoDateTaken(value)
                break
            case 'albums':
                setSelectedAlbum(value)
                break
            case 'deleteCheck':
                setDeleteSafe(checked)
                break
            default:
                break
        }
    }

    const cancelPhotoEdit = (event) => {
        event.preventDefault()
        setActivePhoto()
    }

    const deletePhoto = async (event) => {
        event.preventDefault()
        if (deleteSafe === false) {
            return false
        }
        try {
            const result = await fetch(`${BASE_URL}/api/photos/${activePhoto}`, {
                method: 'DELETE'
            })
            await result.json()
            if (result.status === 200) {
                setActivePhoto()
                setDataChanged(true)
                setFormMessage('Photo Deleted')
            } else {
                setFormMessage('There was an error...')
            }
            setDeleteSafe(false)
        } catch (error) {
            console.error(error)
        }
    }

    const addPhotoToAlbum = async (event) => {
        event.preventDefault()
        try {
            const result = await fetch(`${BASE_URL}/api/albumsphotos`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    photoId: activePhoto,
                    albumId: selectedAlbum,
                    serial: -1
                })
            })
            const data = await result.json()
            if (result.status === 201) {
                setSelectedAlbum('')
                setFormMessage('Photo Added to Album')
                setDataChanged(true)
            } else {
                setFormMessage('There was an error...')
            }
            console.log(data)
        } catch (error) {
            console.error(error)
        }
    }

    const removePhotoFromAlbum = async (event) => {
        event.preventDefault()
        try {
            const result = await fetch(`${BASE_URL}/api/albumsphotos/${selectedAlbum}/${activePhoto}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const data = await result.json()
            if (result.status === 200) {
                if (selectedAlbum === activeAlbum) {
                    setActiveAlbum(activeAlbum)
                    setActivePhoto(null)
                    setFormMessage('Photo Removed from Album')
                    setDataChanged(true)
                } else {
                    setSelectedAlbum('')
                    setFormMessage('Photo Removed from Album')
                    setDataChanged(true)
                }
            } else {
                setFormMessage('There was an error...')
            }
            console.log(data)
        } catch (error) {
            console.error(error)
        }
    }

    const setAlbumCoverPhoto = async (event) => {
        event.preventDefault()
        try {
            const result = await fetch(`${BASE_URL}/api/albums/${selectedAlbum}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...albumsData[selectedAlbum],
                    cover: activePhoto
                })
            })
            const data = await result.json()
            if (result.status === 200) {
                setFormMessage('Album Updated')
                setDataChanged(true)
            } else {
                setFormMessage('There was an error...')
            }
            console.log(data)
        } catch (error) {
            console.error(error)
        }
    }

    const savePhotoData = async (event) => {
        event.preventDefault()
        try {
            const result = await fetch(`${BASE_URL}/api/photos/${activePhoto}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...photosData[activePhoto],
                    title: photoTitle,
                    comments: photoComments,
                    status: photoStatus,
                    dateTaken: photoDateTaken
                })
            })
            const data = await result.json()
            if (result.status === 200) {
                setFormMessage('Photo Updated')
                setDataChanged(true)
            } else {
                setFormMessage('There was an error...')
            }
            console.log(data)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div>
            <form onSubmit={savePhotoData} className={``} >
                <img onClick={() => setModalActive(true)} src={`${BASE_URL}/images/thumbs/lr-${activePhoto}.jpg`} alt='A' className='border-8 shadow-lg cursor-pointer border-stone-50' />
                <input type="text" name="title" placeholder="Title" value={photoTitle} onChange={e => handleChange(e)} className={`w-full p-2 mt-2 border-stone-200 border`} /><br />
                <textarea name="comments" placeholder="Comments" value={photoComments} onChange={e => handleChange(e)} className={`w-full p-2 mt-2 border-stone-200 border`} /><br />
                <select name='status' value={photoStatus} onChange={e => handleChange(e)} className={`w-full p-2 mt-0 border-stone-200 border`}>
                    <option value='new'>New</option>
                    <option value='active'>Active</option>
                    <option value='hidden'>Hidden</option>
                </select><br />
                <input type="text" name="dateTaken" placeholder="Date Taken" value={photoDateTaken} onChange={e => handleChange(e)} className={`w-full p-2 mt-2 border-stone-200 border`} /><br />
                <button className={`bg-stone-600 text-stone-100 py-1 px-3 mt-2`}>Save Photo</button>
            </form>
            <form onSubmit={cancelPhotoEdit}>
                <button className={`hidden bg-stone-200 border border-stone-300 text-stone-600 py-1 px-3 mt-2`}>Cancel</button>
            </form>
            <form onSubmit={deletePhoto} className={`py-2`}>
                <input type='checkbox' name='deleteCheck' checked={deleteSafe} onChange={e => handleChange(e)} />
                <button disabled={deleteSafe === false} className={`disabled:opacity-20 disabled:cursor-not-allowed bg-stone-600 text-stone-100 py-1 px-3 ml-2`}>Delete Photo</button>
            </form>
            <hr />
            <form onSubmit={photoAlbums.includes(selectedAlbum) ? removePhotoFromAlbum : addPhotoToAlbum}>
                <select name='albums' value={selectedAlbum} onChange={e => handleChange(e)} className={`w-full p-2 mt-2 border-stone-200 border`}>
                    <option value={''} disabled>Select Album...</option>
                    {
                        Object.keys(albumsData).map(album => {
                            const formatting = photoAlbums.includes(album) ? 'font-bold' : ''
                            return (
                                <option key={album} value={albumsData[album].albumId} className={formatting}>
                                    {albumsData[album].title}
                                </option>
                            )
                        })
                    }
                </select><br />
                <button disabled={selectedAlbum === ''} className={`disabled:opacity-20 disabled:cursor-not-allowed bg-stone-600 text-stone-100 py-1 px-3 my-2`}>{photoAlbums.includes(selectedAlbum) ? `Remove from Album` : `Add to Album`}</button>
            </form>
            {
                photoAlbums.includes(selectedAlbum) && <div>
                    <form onSubmit={setAlbumCoverPhoto}>
                        <button disabled={selectedAlbum === ''} className={`disabled:opacity-20 disabled:cursor-not-allowed bg-stone-200 border border-stone-300 text-stone-600 py-1 px-3`}>Set Album Cover Photo</button>
                    </form>
                </div>
            }
            <hr />
        </div>
    )
}

export default PhotoForm