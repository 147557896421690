import React from 'react'
import { BASE_URL } from './Constants'

const UploadForm = ({ setDataChanged, setFormMessage }) => {
    const [fileName, setFileName] = React.useState('')
    const [fileData, setFileData] = React.useState()
    const handleFileChange = (event) => {
        setFileData([...event.target.files])
        setFileName(event.target.value)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const formData = new FormData()
        fileData.forEach((file, i) => {
            formData.append(`photos[]`, file, file.name)
        })
        try {
            await fetch(`${BASE_URL}/api/photos`, {
                method: 'POST',
                body: formData,
            })
            setFileName('')
            setFileData(null)
            setDataChanged(true)
            setFormMessage('Photo(s) uploaded')
        } catch (error) {
            console.error(error)
            setFormMessage(error)
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <input type='file' name="photos[]" multiple={true} value={fileName} onChange={handleFileChange} className={`w-full p-2 mt-2 border-stone-200 border`} /><br />
            <button disabled={!fileData} className={`disabled:opacity-20 disabled:cursor-not-allowed bg-stone-600 text-stone-100 py-1 px-3 mt-2`}>Upload Photos</button>
        </form>
    )
}

export default UploadForm