import React, { useState, useEffect } from 'react'
import UploadForm from './UploadForm'
import AlbumForm from './AlbumForm'
import PhotoForm from './PhotoForm'
import AlbumsList from './AlbumsList'
import PhotosList from './PhotosList'
import { BASE_URL } from './Constants'

const Editor = () => {
    const [dataChanged, setDataChanged] = useState(true)
    const [activeAlbum, setActiveAlbum] = useState()
    const [activeFilter, setActiveFilter] = useState('all')
    const [activePhoto, setActivePhoto] = useState()
    const [modalActive, setModalActive] = useState(false)
    const [albumsData, setAlbumsData] = useState(false)
    const [photosData, setPhotosData] = useState(false)
    const [albumsPhotosData, setAlbumsPhotosData] = useState(false)
    const [formMessage, setFormMessage] = React.useState('')

    useEffect(() => {
        if (dataChanged === false) {
            return
        }
        fetch(`${BASE_URL}/api/albumsphotos`, { cache: 'no-store' }).then(response => {
            console.log('fetch albumsphotos data...')
            return response.json()
        }).then(json => {
            setAlbumsPhotosData(() => json)
        })

        fetch(`${BASE_URL}/api/albums/?orderby=title&keyby=albumId`, { cache: 'no-store' }).then(response => {
            console.log('fetch albums data...')
            return response.json()
        }).then(json => {
            setAlbumsData(() => json)
        })

        fetch(`${BASE_URL}/api/photos/?orderby=dateTaken&keyby=photoId`, { cache: 'no-store' }).then(response => {
            console.log('fetch photos data...')
            return response.json()
        }).then(json => {
            setPhotosData(() => json)
        })

        setDataChanged(() => false)
    }, [dataChanged])

    return (
        <div>
            {
                modalActive && activePhoto && (<div className='fixed top-0 left-0 flex items-center justify-center w-full h-full opacity-100'>
                    <div onClick={() => setModalActive(false)} className='absolute z-10 w-full h-full bg-gray-900 cursor-pointer opacity-80'></div>
                    <div onClick={() => setModalActive(false)} className='z-50 p-4 mx-auto overflow-y-auto shadow-lg cursor-pointer bg-stone-50'>
                        <img src={`${BASE_URL}/images/crops/1080-${activePhoto}.jpg`} alt='A' className={`w-full max-w-[100%] max-h-[800px]`} />
                    </div>
                </div>)
            }
            <main className='grid h-screen max-h-screen grid-cols-12 overflow-hidden'>
                <div className='col-span-2 p-2 overflow-y-auto bg-stone-100'>
                    {
                        albumsData && (
                            <AlbumsList albumsData={albumsData} activeAlbum={activeAlbum} setActiveAlbum={setActiveAlbum} activeFilter={activeFilter} setActiveFilter={setActiveFilter} setActivePhoto={setActivePhoto} />
                        )
                    }
                    <AlbumForm setDataChanged={setDataChanged} activeAlbum={activeAlbum} setActiveAlbum={setActiveAlbum} albumsData={albumsData} setFormMessage={setFormMessage} />
                </div>
                <div className='col-span-8 overflow-y-auto bg-stone-50'>
                    {
                        formMessage && (
                            <p onClick={() => setFormMessage('')} className='absolute p-2 -translate-x-1/2 bg-stone-300 bottom-2 left-1/2'>{formMessage}</p>
                        )
                    }
                    {
                        photosData && (
                            <PhotosList photosData={photosData} albumsPhotosData={albumsPhotosData} activeAlbum={activeAlbum} activeFilter={activeFilter} activePhoto={activePhoto} setActivePhoto={setActivePhoto} />
                        )
                    }
                </div>
                <div className='col-span-2 p-2 overflow-y-auto bg-stone-100'>
                    {
                        photosData && activePhoto && (
                            <PhotoForm setDataChanged={setDataChanged} activePhoto={activePhoto} setActivePhoto={setActivePhoto} activeAlbum={activeAlbum} setActiveAlbum={setActiveAlbum} photosData={photosData} albumsData={albumsData} albumsPhotosData={albumsPhotosData} setFormMessage={setFormMessage} setModalActive={setModalActive} />
                        )
                    }
                    <UploadForm setDataChanged={setDataChanged} setFormMessage={setFormMessage} />
                </div>
            </main>
        </div>
    )
}

export default Editor